import { StyledCartItem } from "./CartItem.styled";
import Image from "next/image";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { removeFromCart } from "@/redux/actions/removeFromCart";
import { setProductQuantity } from "@/redux/actions/setProductQuantity";
import { WPImage } from "../WPImage/WPImage";

export default function CartItem({ product }: any) {
  const dispatch = useDispatch();

  const handleRemove = () => dispatch(removeFromCart(product));

  const handleQuantity = (e: any) => {
    const value = Number(e.target.value);
    dispatch(setProductQuantity(product, value));
  };

  const options = [{ value: 1, label: 1 }];
  for (let i = 2; i <= 50; i++) {
    options.push({ value: i, label: i });
  }

  const productUrl = product.name.replaceAll(/ /g, "-").toLowerCase();

  return (
    <StyledCartItem key={product.id}>
      <Link href={`/produkter/${productUrl}`}>
        <div className="cart__img">
          {product.image && <WPImage image={product.image} />}
        </div>
      </Link>
      <div className="cart__info-wrapper">
        <div className="cart__top-info">
          <Link href={`/produkter/${productUrl}`}>
            <div className="cart__top-info__title details-big">
              {product.name}
            </div>
          </Link>
          <div className="cart__remove-btn" onClick={handleRemove}>
            <div className="cart__remove-btn__stem1" />
            <div className="cart__remove-btn__stem2" />
          </div>
        </div>
        {product.attributes && (
          <div className="cart__product-description details-small">
            {product.attributes[1]?.option} | {product.variationName}
          </div>
        )}
        <div className="cart__product-price-wrapper">
          <div>
            <div className="select">
              <select
                onChange={handleQuantity}
                value={Number(product.quantity)}
              >
                {options.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

              <div className="select_text">ST</div>
              <div className="select_arrow"></div>
            </div>
          </div>
          <div className="cart__price details-big">
            {product.price * product.quantity} sek
          </div>
        </div>
      </div>
    </StyledCartItem>
  );
}
