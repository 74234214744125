
import { Page } from '@/types/Page'
import  Axios from "axios";
import { IWPImage } from "@/types/WPImage";
import api from './api';

export type WCProduct = {
  id: number
  name: string
  slug: string
  images: any[]
}


const wpClient = Axios.create({
  baseURL: process.env.BASE_URL + "wp-json/",
})


const getProducts = async (perPage = 10, page = 1): Promise<ReadonlyArray<WCProduct>> => {
  const res = await api.get("products", {per_page: perPage, page});
  const products = res.data;
  return products;
};

const getProductBySlug = async (slug: string) => {
  const res = await api.get("products", {
    slug: slug,
    limit: 1,
  });
  const products = res.data;
  return products[0];
};

const getProductVariations = async (
  
  productId: number,
  variationIds: Array<number>
) => {
  return await Promise.all(
    variationIds.map(async (variation) => {
      return (await api.get(`products/${productId}/variations/${variation}`))
        .data;
    })
  );
};

const getPageTemplate = async (productId: number) => {
  const response = await wpClient.get(`acf/v3/product/${productId}`)

  return response.data.acf.sidmall;
}

const getStartPage = async () => {
  const response = await wpClient.get('wp/v2/pages?slug=start')

  return response.data
}

const getThankYouPage = async () => {
  const response = await wpClient.get('wp/v2/pages?slug=thank-you')

  return response.data[0]
}

const getPages = async (): Promise<ReadonlyArray<Page>> => {  
  const response = await wpClient.get('wp/v2/pages')
  return response.data
}

const getPagesBySlug = async (slug: string): Promise<ReadonlyArray<Page>> => {
  const response = await wpClient.get('wp/v2/pages', {params: {slug}})
  return response.data
}


const getOptions = async () => {
  const response = await wpClient.get('acf/v3/options/options')
  return response.data
}




const getMediaById = async (id: number): Promise<IWPImage>  => {
  const response = await wpClient(`wp/v2/media/${id}`)
  const image = response.data

  return {
    url: image.source_url,
    width: image.media_details.width,
    height: image.media_details.height,
    alt: image.alt_text || ''
  }
}


const fetchImagesForProduct = async (product: WCProduct) => {
  const images = await Promise.all(product.images.map(image => getMediaById(image.id)))

  return {
    ...product,
    images
  }
}

const fetchImageForVariation = async (variation: any) => {
  const image = await getMediaById(variation.image.id)

  return {
    ...variation,
    image
  }
}

export { getProducts, getProductBySlug, getProductVariations, getPageTemplate, getStartPage, getThankYouPage, getOptions,  getPages, getPagesBySlug, fetchImagesForProduct, fetchImageForVariation };
