import { useEffect, useRef } from "react";
import { InitialState } from "@/redux/reducers/cartReducer";
import { useSelector, useDispatch } from "react-redux";
import { StyledCart } from "./Cart.styled";
import { bool } from "prop-types";
import Link from "next/link";
import CartList from "../CartList";
import CartTotalPrice from "../CartTotalPrice";

type Props = {
  open: boolean;
};

function Cart({ open }: Props) {
  const cart = useSelector(({ cart }: { cart: InitialState }) => {
    return cart.cart;
  });
  const isCartOpen = useSelector((state: any) => state.cart.isCartOpen);

  function closeIfClickedOutsideCart(ref: any, dispatch: any, open: boolean) {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target) && isCartOpen) {
        dispatch({ type: "CLOSE_CART" });
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }

  const dispatch = useDispatch();

  const wrapperRef = useRef<HTMLDivElement>(null);
  closeIfClickedOutsideCart(wrapperRef, dispatch, open);

  // Prevent body scrolling when cart is open on mobile/tablet/small screens
  useEffect(() => {
    if (isCartOpen && window.innerWidth <= 620) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflow = "";
      document.body.style.height = "100%";
    }
  }, [isCartOpen]);

  return (
    <StyledCart ref={wrapperRef} open={open}>
      <div>
        <div className="cart__title details-big">kundvagn</div>
        <div className="cart__slogan-container">
          <div className="cart__slogan bread-small">Fri frakt</div>
          <div className="cart__slogan bread-small">Snabb leverans</div>
        </div>
      </div>
      <div className="this-is-the-amazing-div">
        <CartList products={cart} />
      </div>
      <div className="cart__total-price-wrapper">
        <div className="cart__total-price-div">
          <CartTotalPrice products={cart} />
        </div>
        <Link prefetch={false} href="/checkout">
          <button className="cart__btn primary-button button-small button-filled">
            TILL FRAKT OCH BETALNING
          </button>
        </Link>
      </div>
    </StyledCart>
  );
}

Cart.propTypes = {
  open: bool.isRequired,
};

export default Cart;
