import React from "react";
import Image from "next/image";
import { IWPImage } from "@/types/WPImage";

type Props = {
  image: IWPImage;
  height?: string;
  width?: string;
  layout?: any;
  priority?: boolean;
};

export const WPImage: React.FC<Props> = (props) => {

  if (!props.image) {
    return null
  }
  
  
  const urlParts = props.image.url.split("/");
  const folder = urlParts[urlParts.length - 2];
  const filename =
    urlParts[urlParts.length - 1]?.indexOf("?") !== -1
      ? urlParts[urlParts.length - 1]?.split("?")[0]
      : urlParts[urlParts.length - 1];
  return (
    <Image
      src={`${folder}/${filename}`}
      height={props.height || props.image.height}
      width={props.width || props.image.width}
      alt={props.image.alt}
      priority={props.priority}
      layout={props.layout}
    />
  );
};
