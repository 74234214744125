import { useState } from "react";
import Modal from "../Modal/Modal";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

const AgeCheck = ({ options }: any) => {
  const [showModal, setShowModal] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["age-check"]);

  const dispatch = useDispatch();

  const ageCheckCookie = !!cookies["age-check"];

  const dayInMilliseconds = 86400000;
  const oneWeekFromToday = new Date(Date.now() + dayInMilliseconds * 7);

  if (ageCheckCookie !== true) {
    dispatch({ type: "SHOW_AGE_CHECK", showAgeCheck: true });
  }

  const handleOnClick = () => {
    setShowModal(false);
    dispatch({ type: "SHOW_AGE_CHECK", showAgeCheck: false });
    setCookie("age-check", true, { expires: oneWeekFromToday });
  };

  const modalOptions = options.acf.age_check;

  return (
    <>
      <Modal
        options={modalOptions}
        title={""}
        onClose={() => setShowModal(false)}
        show={showModal && !ageCheckCookie}
      >
        <button className="primary-button"
          onClick={() => {
            handleOnClick();
          }}
        >
          <div className="btn-content">
            <span className="checkmark">
              <div className="checkmark_stem"></div>
              <div className="checkmark_kick"></div>
            </span>
            Jag ÄR ÖVER 25 ÅR
          </div>
        </button>
        <button className="primary-button"
          onClick={() => {
            setShowModal(true);
            removeCookie("age-check");
          }}
        >
          {" "}
          <div className="btn-content">
            <span className="cross">
              <div className="cross_stem_1"></div>
              <div className="cross_stem_2"></div>
            </span>
            Jag ÄR UNDER 25 ÅR
          </div>
        </button>
      </Modal>
    </>
  );
};

export default AgeCheck;
