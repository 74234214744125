import React, { useRef, useState } from "react";
import { StyledNewsLetter } from "./NewsLetter.styled";
import axios from "axios";
import { IWPImage } from "@/types/WPImage";
import { WPImage } from "../WPImage/WPImage";

import Button from "../Button/Button";

type Props = {
  news_letter: {
    text: string;
    message: string;
    loading_image: IWPImage;
    thank_you_text: string;
  };
};

type FormData = {
  email: string;
};

type FormState = {
  isLoading: boolean;
  error?: string;
  data: FormData;
  success: boolean;
};

const emptyForm = (): FormData => ({
  email: "",
});

const emptyFormState = (): FormState => ({
  isLoading: false,
  data: emptyForm(),
  success: false,
});

const NewsLetter = ({ news_letter }: Props) => {
  const [formState, setFormState] = useState(emptyFormState());
  const [email, setEmail] = useState("");

  const submitForm = () => {
    //validate email
    const regEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email.toLowerCase().match(regEx)) {
      setFormState(() => ({
        success: false,
        isLoading: false,
        data: emptyForm(),
        error: "Ange en giltig email-adress.",
      }));
      return;
    }
    setFormState(emptyFormState());

    subscribeUser(email)
      .then(() => {
        setFormState((state) => ({
          ...state,
          success: true,
        }));
        setEmail("");
      })
      .catch(() => {
        setEmail("");
        setFormState((state) => ({
          ...state,
          error: "Något gick fel. Försök igen senare.",
        }));
      });
  };

  const subscribeUser = async (email: string) => {
    return new Promise(async (resolve, reject) => {
      const url = "https://spirit.qte.nu/wp-json/custom/v1/subscribe";

      const data = {
        email: email,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      try {
        console.log("Subscribing user:", email);
        const response = await axios.post(url, data, { headers });
        resolve(true);
        console.log("User subscribed successfully:", response.data);
      } catch (error: any) {
        reject(false);
        if (error.response) {
          console.error("Error subscribing user:", error.response.data);
        } else {
          console.error("Error subscribing user:", error.message);
        }
      }
    });
  };

  return (
    <StyledNewsLetter>
      <p className="intro">
        Håll dig uppdaterad och prenumerera på vårt nyhetsbrev.
      </p>
      <div className="form">
        <div>
          <input
            value={email}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submitForm();
              }
            }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="input"
            placeholder="Ange din email-adress här..."
          />
          <Button
            onClick={() => {
              submitForm();
            }}
            title="PRENUMERERA"
            color={"black"}
            background={"white"}
          />
        </div>
        {formState.isLoading && <p>Loading..</p>}
        {formState.success && (
          <p>
            Tack för att du prenumererar! <br></br> Du kommer få ett
            bekräftelsemail inom kort.
          </p>
        )}

        {formState.error && <p>{formState.error}</p>}
      </div>
    </StyledNewsLetter>
  );
};

export default NewsLetter;
