import styled from "styled-components";
import { theme } from "themeConfig";

interface StyledFooterProps {
  backgroundImage?: string;
}

export const StyledMobileFooter = styled.footer<StyledFooterProps>`
  position: relative;
  padding: ${theme.padding.mobile};
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: ${theme.canvas.darkBlue};
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : "none"};
  background-size: cover;

  .main-inner {
    min-height: unset;
    padding-top: 0;
    padding-bottom: 0;
  }

  .mobile-footer__container__brand-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    .mobile-footer__slogan {
      color: ${theme.text.lightGrey};
      margin: 0;
      margin-left: 20px;
    }
  }

  .mobile-footer-groups {
    display: flex;
    margin-bottom: 50px;
    .mobile-footer-group {
      width: 50%;
    }
  }

  .mobile-footer-group-title {
    margin: 0;
    color: ${theme.text.white};
    margin-bottom: 12px;
  }

  .mobile-footer-group-links {
    display: flex;
    flex-direction: column;
    .mobile-footer__link {
      color: ${theme.text.altGrey};
      text-decoration: none;
      margin-bottom: 12px;
      &:hover {
        color: ${theme.text.white};
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .mobile-footer-socials {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    .mobile-footer-group-title {
      width: 100%;
    }
  }

  .mobile-footer-bottom__social-media-icon-wrapper {
    margin-right: 10px;
  }

  .mobile-footer-bottom__social-media-link {
    background: ${theme.canvas.primary};
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 6px;
  }

  .mobile-footer-bottom__social-media-icon {
    width: 100%;
    height: 100%;
    span,
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
    }
  }

  // white part of the mobile-footer

  .mobile-footer-bottom {
    display: flex;
    width: 100%;
    background-color: ${theme.canvas.darkBlue};
    padding: 10px;
  }

  .mobile-footer-bottom__emblem {
    width: 250px;
  }

  .mobile-footer-bottom__social-media-text {
  }

  .mobile-footer-bottom__social-media-icon-wrapper {
  }

  .mobile-footer-bottom__social-media-link {
  }

  .mobile-footer-bottom__social-media-icon {
  }

  @media screen and (min-width: ${theme.breakpoints.small}) {
    display: none;
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    .main-inner {
      padding: 0 20px;
    }
  }
`;
