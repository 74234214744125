import styled from "styled-components";
import { theme } from "themeConfig";

export const StyledMenuBarMobile = styled.nav<{
  openMenu: boolean;
  background: any;
  mobile_background: any;
}>`
  .mobile-menu__container {
    box-sizing: border-box;
    padding: 0 20px 0 20px;
    width: 100vw;
    height: 60px;
    position: fixed;
    top: 95px;
    left: 0;
    z-index: 5;
    background: ${(props: any) =>
      props.background ? theme.canvas.primary : "transparent"};
    box-shadow: ${(props: any) =>
      props.background ? theme.shadows.primary : "none"};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease-in-out;

    //open menu and backround is primary
    ${({ openMenu }) =>
      openMenu ? `background: ${theme.canvas.primary} !important;` : ""}

    @media screen and (max-width: 567px) {
      top: 105px;
    }

    @media screen and (max-width: 321px) {
      top: 125px;
    }

    @media screen and (max-width: 254px) {
      top: 145px;
    }
  }

  .mobile-menu__logo {
    width: 113px;
    span {
      width: 100% !important;
    }
    img {
      object-fit: cover;
      min-height: unset;
      min-height: unset !important;
      height: auto !important;
    }
  }

  .mobile-menu__cart-and-burger__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mobile-menu__cart {
    position: relative;
    width: 33px;
    height: 33px;
    margin-right: 14px;
    z-index: 1;
    img {
      object-fit: contain;
    }
  }

  .mobile-cart-quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #f240ba -1.91%, #f9dd53 112.3%);

    text-align: center;
    color: ${theme.text.white};
    font-family: sans-serif;

    height: 18px;
    width: 18px;
    font-size: 12px;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 9;
  }

  .mobile-menu__burger-container {
    width: 32px;
    height: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 21;
  }

  .mobile-menu__burger-1 {
    width: 32px;
    height: 1px;
    background-color: black;
    transform: ${({ openMenu }) =>
      openMenu
        ? "rotate(-45deg) translateX(-8.5px) "
        : "rotate(0) translateX(0)"};
    transition: transform 0.3s ease-in-out;
  }

  .mobile-menu__burger-2 {
    width: 32px;
    height: 1px;
    background-color: black;
    transform: ${({ openMenu }) =>
      openMenu ? "translateX(60px)" : "translateX(0)"};
    /* opacity: ${({ openMenu }) => (openMenu ? "0" : "1")}; */
    transition: transform 0.3s ease-in-out;
  }

  .mobile-menu__burger-3 {
    width: 32px;
    height: 1px;
    background-color: black;
    transform: ${({ openMenu }) =>
      openMenu
        ? "rotate(45deg) translateX(-8.5px)"
        : "rotate(0) translateX(0)"};
    transition: transform 0.3s ease-in-out;
  }

  .mobile-menu__window {
    box-sizing: border-box;
    padding: 75px 30px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 155px);
    position: fixed;
    top: 155px;
    left: 0;
    background-color: ${theme.canvas.darkBlue};
    /* background-image: url(${({ mobile_background }) =>
      mobile_background.url}); */
    transform: ${({ openMenu }) =>
      openMenu ? "translateX(0)" : "translateX(100%)"};
    transition: transform 0.3s ease-in-out;
    z-index: 20;
  }

  .mobile-menu__window-background {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    z-index: -1;
    span,
    img {
      display: block !important;
      height: 100% !important;
      width: 100% !important;
      object-fit: cover;
      object-position: bottom right;
    }
  }

  .mobile-menu__link {
    color: ${theme.text.white};
    text-decoration: none;
    margin-bottom: 25px;
    font-size: 17px;
  }

  @media screen and (min-width: calc(${theme.breakpoints.small} + 1px)) {
    display: none;
  }
`;
