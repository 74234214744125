import styled from "styled-components";
import { theme, loading } from "themeConfig";

export const StyledNewsLetter = styled.div`
  .form > div {
    display: flex;

    @media screen and (max-width: ${theme.breakpoints.small}) { 
      flex-direction: column;

      input {
        text-align: center;
      }
    }

    input {
      background: transparent;
      color: ${theme.text.white};
      border: 1px solid ${theme.text.altGrey};
      padding: 0 20px;
      outline: none;
      height: 47px;
    }

    button {
      height: 47px;
      font-weight: 200;
      font-family: ${theme.fonts.secondary};
      font-size: 12px;
    }
  }

  p {
    color: white;
    font-family: ${theme.fonts.secondary};
    line-height: 1.5;
  }

  .intro {
    font-family: ${theme.fonts.primary};
    font-style: normal;
    font-weight: 400;
    color: ${theme.text.altGrey};
    font-size: 13px;
    line-height: 150%;
  }

  .news-letter-text {
    color: ${theme.text.altGrey};
    margin: 0;
    margin-bottom: 20px;
  }

  form {
    display: flex;
  }

  .news-letter-text-field {
    appearence: none;
    background: none;
    border: 1px solid #828282;
    border-radius: 3px 0px 0px 3px;
    color: ${theme.text.white};
    padding: 10px 15px;
    width: 220px;
    &::placeholder {
      color: ${theme.text.altGrey};
    }
    &:focus {
      outline: none;
    }
  }

  .news-letter-thank-you-text {
    color: ${theme.text.altGrey};
  }

  .news-letter-button {
    border-radius: 0 3px 3px 0;
    @media screen and (max-width: ${theme.breakpoints.small}) {
      padding: 18px 18px 15px;
      font-size: 10px;
    }
  }
`;
