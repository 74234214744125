import React, { useEffect } from "react";

import Link from "next/link";
import { StyledFooter } from "./Footer.styled";
import { WPImage } from "../WPImage/WPImage";
import NewsLetter from "../NewsLetter/NewsLetter";

interface Props {}

const Footer = ({ pageProps }: any) => {
  const {
    title,
    links,
    logo,
    link_group_1,
    link_group_2,
    link_group_3,
    news_letter,
    bottom_row,
    background_image_desktop,
  } = pageProps;

  return (
    <StyledFooter backgroundImage={background_image_desktop}>
      <div className="main-inner">
        <Link href={"/"}>
          <div className="footer__container__brand-wrapper">
            <div className="footer__icon">
              <WPImage image={logo} />
            </div>
            <h4 className="footer__slogan h4-caps">{title}</h4>
          </div>
        </Link>

        <div className="footer-groups">
          <div className="footer-group footer-group-2">
            {link_group_2.title && (
              <h4 className="footer-group-title details-small">
                {link_group_2.title}
              </h4>
            )}
            <div className="footer-group-links">
              {link_group_2.links.map((link: any, index: number) => {
                return (
                  <Link href={link.link} key={index}>
                    <a className="footer__link bread-small"> {link.name}</a>
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="footer-group footer-group-3">
            {link_group_3.title && (
              <h4 className="footer-group-title details-small">
                {link_group_3.title}
              </h4>
            )}
            <div className="footer-group-links">
              {link_group_3.links.map((link: any, index: number) => {
                return (
                  <Link href={link.link} key={index}>
                    <a className="footer__link bread-small"> {link.name}</a>
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="footer-group footer-group-4">
            {news_letter.title && (
              <>
                <h4 className="footer-group-title details-small">
                  {news_letter.title}
                </h4>
                <NewsLetter news_letter={news_letter} />
              </>
            )}
            {bottom_row && (
              <div className="footer__bottom__social-media-wrapper">
                <div className="footer-socials">
                  {bottom_row.social_media && (
                    <p className="bread footer-bottom__social-media-text bread-small">
                      {bottom_row.social_media.text}
                    </p>
                  )}

                  {bottom_row.social_media &&
                    bottom_row.social_media.accounts.map(
                      (account: any, index: number) => {
                        return (
                          <div
                            className="footer-bottom__social-media-icon-wrapper"
                            key={index}
                          >
                            <Link href={account.link} passHref>
                              <a
                                className="footer-bottom__social-media-link"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className="footer-bottom__social-media-icon">
                                  <WPImage image={account.icon} />
                                </div>
                              </a>
                            </Link>
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
