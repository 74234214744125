import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import MenuBar from "./MenuBar";
import Cart from "./Cart";
import MenuBarMobile from "./MenuBar/MenuBarMobile";
import Footer from "./Footer/Footer";
import MobileFooter from "./Footer/MobileFooter";
import { RotatingIcon } from "./RotatingIcon/RotatingIcon";
import { NewsletterPopup } from "./NewsletterPopup/NewsletterPopup";

function Layout({ options, children }: any) {
  const dispatch = useDispatch();
  const cart = useSelector((state: any) => {
    return state.cart.cart;
  });

  const isCartOpen = useSelector((state: any) => {
    return state.cart.isCartOpen;
  });
  const toggleCart = (state: boolean) => {
    dispatch({ type: "TOGGLE_CART", payload: state });
  };

  const showAgeCheck = useSelector((state: any) => {
    return state.cart.showAgeCheck;
  });

  const router = useRouter();

  useEffect(() => {
    dispatch({ type: "CLOSE_CART" });
  }, [useRouter()]);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (isCartOpen && window.innerWidth <= 620) {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }, []);

  if (showAgeCheck) {
    return <></>;
  } else if (router.asPath === "/checkout") {
    return (
      <>
        <MenuBar pageProps={options.acf.menu} />
        <MenuBarMobile toggle={toggleCart} pageProps={options.acf.menu} />
        {children}
        <MobileFooter pageProps={options.acf.footer} />
        <Footer pageProps={options.acf.footer} />
      </>
    );
  } else {
    return (
      <>
        <MenuBarMobile toggle={toggleCart} pageProps={options.acf.menu} />
        <MenuBar pageProps={options.acf.menu} />
        {/* Ändra här */}
        {/* <Burger
          open={isCartOpen}
          toggle={toggleCart}
          pageProps={options.acf.menu}
        /> */}
        <Cart open={isCartOpen} />
        {children}
        <MobileFooter pageProps={options.acf.footer} />
        <Footer pageProps={options.acf.footer} />
        <NewsletterPopup {...options.acf} />
      </>
    );
  }
}

export default Layout;
