import type { AppProps } from "next/app";
import AgeCheck from "@/components/AgeCheck/AgeCheck";
import store from "@/redux/store";
import { Provider, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, theme } from "../themeConfig";
import Layout from "@/components/Layout";
import { PersistGate } from "redux-persist/integration/react";
import * as api from "../utils/woocommerce/api";
import "../styles/global.css";
import Script from "next/script";

function MyApp({ Component, pageProps, options, products }: any) {
  /*

    // import("react-facebook-pixel")
    //   .then((x) => x.default)
    //   .then((ReactPixel) => {
    //     ReactPixel.init("");
    //     ReactPixel.pageView();

    //     Router.events.on("routeChangeComplete", () => {
    //       ReactPixel.pageView();
    //     });
    //   });

  }, []); */

  return (
    <Provider store={store().store}>
      <div className="container">
      {/* <!-- Google Tag Manager --> */}
      <Script id="gtag">{`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KSJMFQK2');
      `}</Script>

      </div>
      <PersistGate loading={null} persistor={store().persistor}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <AgeCheck options={options} />
          <Layout options={options}>
            <Component {...pageProps} />
          </Layout>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

// get initial props
MyApp.getInitialProps = async () => {
  const options = await api.products.getOptions();

  const products = await api.products.getProducts();
  const productsWithImages = await Promise.all(
    products.map((p) => api.products.fetchImagesForProduct(p))
  );

  return { props: { products: productsWithImages }, options };
};

export default MyApp;
