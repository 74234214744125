import React, { useState, useEffect } from "react";
import { StyledMenuBarMobile } from "./MenuBarMobile.styled";
import { StyledBurger } from "../Burger/Burger.styled";
import Link from "next/link";
import Image from "next/image";
import { WPImage } from "../WPImage/WPImage";

import { useSelector } from "react-redux";

type Props = {
  toggle: (state: boolean) => void;
  pageProps: any;
};

const MenuBarMobile = ({ toggle, pageProps }: Props) => {
  const { logo, cart_icon, mobile_menu_background } = pageProps;
  const [openMenu, setOpenMenu] = useState(false);

  // cart quantity on cart icon
  const cartQuantity = useSelector((state: any) => state.cart.cart.length);

  const renderCartQuantity = () => {
    if (cartQuantity > 0) {
      return <div className="mobile-cart-quantity">{cartQuantity}</div>;
    }
  };

  // Prevent body scrolling when cart is open on mobile/tablet/small screens
  useEffect(() => {
    if (openMenu && window.innerWidth <= 620) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "calc(100vh + 1px)";
    } else {
      document.body.style.overflow = "";
      document.body.style.height = "100%";
    }
  }, [openMenu]);

  //handle click event
  const handleOnClick = () => toggle(!open);
  const handleOnClickMenu = () => setOpenMenu(!openMenu);

  // Toggle menu background on scroll
  const [clientWindowHeight, setClientWindowHeight] = useState(0);

  const handleScroll = () => {
    setClientWindowHeight(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const [background, setBackground] = useState(false);

  useEffect(() => {
    if (clientWindowHeight < 1) {
      setBackground(false);
    } else {
      setBackground(true);
    }
  }, [clientWindowHeight]);

  // mobile links
  const mobileLinks = pageProps.mobile_links.map((link: any) => {
    return (
      <Link passHref href={link.link_path} key={link.link_text}>
        <a
          className="mobile-menu__link details-big"
          onClick={handleOnClickMenu}
        >
          {link.link_text}
        </a>
      </Link>
    );
  });

  return (
    <StyledMenuBarMobile
      mobile_background
      background={background}
      openMenu={openMenu}
    >
      <div className="mobile-menu__container">
        <Link href="/">
          <div className="mobile-menu__logo">
            <WPImage image={logo} />
          </div>
        </Link>
        <div className="mobile-menu__cart-and-burger__wrapper">
          {/* Ändra här */}
          {/* <div className="mobile-menu__cart" onClick={handleOnClick}>
            {renderCartQuantity()}
            <WPImage image={cart_icon} height="33" width="33" />
          </div> */}
          <div
            className="mobile-menu__burger-container"
            onClick={handleOnClickMenu}
          >
            <div className="mobile-menu__burger-1" />
            <div className="mobile-menu__burger-2" />
            <div className="mobile-menu__burger-3" />
          </div>
          <div className="mobile-menu__window">
            <div className="mobile-menu__window-background">
              <WPImage image={mobile_menu_background} />
            </div>
            {mobileLinks}
          </div>
        </div>
      </div>
    </StyledMenuBarMobile>
  );
};

export default MenuBarMobile;
