import { products } from "../../utils/woocommerce/api";
import { Product, CartProduct } from "../../types/Product";

export interface InitialState {
  cart: CartProduct[];
  showAgeCheck: boolean;
  isCartOpen: boolean;
}

const initialState: InitialState = {
  cart: [],
  showAgeCheck: true,
  isCartOpen: false,
};

type ActionType =
  | "ADD_TO_CART"
  | "REMOVE_FROM_CART"
  | "SET_PRODUCT_QUANTITY"
  | "SHOW_AGE_CHECK"
  | "TOGGLE_CART"
  | "DISPLAY_CART_QUANTITY"
  | "CLOSE_CART"
  | "OPEN_CART"
  | "CLEAR_CART";

interface Action {
  type: ActionType;
  product: Product;
  quantity: number;
  showAgeCheck: boolean;
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case "TOGGLE_CART":
      return {
        ...state,
        isCartOpen: !state.isCartOpen,
      };

    case "CLEAR_CART":
      return {
        ...state,
        cart: [],
      };

    case "CLOSE_CART":
      return {
        ...state,
        isCartOpen: false,
      };
    case "OPEN_CART":
      return {
        ...state,
        isCartOpen: true,
      };
    case "ADD_TO_CART":
      if (state.cart) {
        const newCart = [...state.cart];
        const product = newCart.find((p) => p.product.id === action.product.id);
        if (product) {
          product.quantity += action.quantity;
        } else {
          newCart.push({
            product: action.product,
            quantity: action.quantity,
          });
        }

        return {
          ...state,
          cart: newCart,
          isCartOpen: true,
        };
      } else {
        return {
          ...state,
          cart: [
            {
              product: action.product,
              quantity: action.quantity,
            },
          ],
          isCartOpen: true,
        };
      }

    case "SHOW_AGE_CHECK":
      return {
        ...state,
        showAgeCheck: action.showAgeCheck,
      };

    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter(
          (item: any) => item.product.id !== action.product.id
        ),
      };

    case "SET_PRODUCT_QUANTITY":
      const newCart = [...state.cart];
      const product = newCart.find((p) => p.product.id === action.product.id);

      if (!product) return state;

      product.quantity = action.quantity;

      return {
        ...state,
        cart: newCart,
        isCartOpen: true,
      };

    default:
      return state;
  }
};
