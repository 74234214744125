import React, { useEffect, useState } from "react";
import { NewsletterPopupStyled } from "./NewsletterPopup.styled";
import Button from "../Button/Button";
import { theme } from "themeConfig";
import { WPImage } from "../WPImage/WPImage";
import { NewsletterPopupForm } from "./NewsletterPopupForm";
import { useCookies } from "react-cookie";

interface NewsletterPopupProps {
  newsletter_title: string;
  newsletter_color: string;
  newsletter_background_color: string;
  newsletter_image: any;
}

export const NewsletterPopup: React.FC<NewsletterPopupProps> = ({
  newsletter_title,
  newsletter_color,
  newsletter_background_color,
  newsletter_image,
}) => {
  //Fetch the route name/slug

  const [isOpened, setIsOpened] = useState(false);
  const [cookies, setCookie] = useCookies(["newsletter_popup"]);

  useEffect(() => {
    if (cookies.newsletter_popup === "closed") {
      return;
    }

    setTimeout(() => {
      setIsOpened(true);
    }, 3000);
  }, []);

  const handleClose = () => {
    setIsOpened(false);
    setCookie("newsletter_popup", "closed", { path: "/" });
  };

  const handleToggle = () => {
    if (isOpened) {
      setIsOpened(false);
      setCookie("newsletter_popup", "closed", { path: "/" });
    } else {
      setIsOpened(true);
      setCookie("newsletter_popup", "opened", { path: "/" });
    }
  };

  return (
    <NewsletterPopupStyled open={isOpened} color={newsletter_background_color}>
      <button onClick={handleToggle} className="hide">
        <svg width="11" height="16" viewBox="0 0 11 16" fill="none">
          <path
            d="M1 15L9.75 8L1 1"
            stroke={theme.colors.primary}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      <div className="title-form">
        <h3 className="title">{newsletter_title}</h3>
        <NewsletterPopupForm
          newsletter_background_color={newsletter_background_color}
          newsletter_color={newsletter_color}
        />
      </div>

      <div className="button_image">
        <button onClick={handleClose} className="close">
          <span>Stäng</span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.30859 1L14.9981 15"
              stroke="#00092F"
              strokeLinecap="round"
            />
            <path
              d="M14.6914 1L1.0019 15"
              stroke="#00092F"
              strokeLinecap="round"
            />
          </svg>
        </button>
        <WPImage image={newsletter_image} />
      </div>
    </NewsletterPopupStyled>
  );
};
