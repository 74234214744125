import styled from "styled-components";
import { theme } from "themeConfig";

interface NewsletterPopupStyledProps {
  open: boolean;
}

export const NewsletterPopupStyled = styled.div<NewsletterPopupStyledProps>`
  position: fixed;
  bottom: 40px;
  right: 40px;

  transition: all 0.5s ease-in-out;
  transform: ${(props) =>
    props.open ? "translateX(0)" : "translateX(calc(100% - 15px))"};

  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  background-color: white;
  padding: 20px;
  padding-right: 0;

  border-radius: 3px;

  display: flex;
  flex-direction: row;
  gap: 10px;

  .hide {
    border: none;
    background-color: transparent;
    display: block;

    margin-bottom: auto;
    transition: all 0.5s ease-in-out;
    transform: translateY(8px)
      rotate(${(props) => (props.open ? "0deg" : "180deg")});
    cursor: pointer;
  }

  .title-form {
    display: flex;
    flex-direction: column;
    width: min-content;
    padding-right: 30px;

    .title {
      font-size: 32px;
      text-transform: uppercase;
      color: ${theme.colors.primary};
      font-family: ${theme.fonts.secondary};
      margin-top: 0;
    }

    .form {
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        flex-direction: row;
      }

      > p {
        font-size: 16px;
        font-family: ${theme.fonts.primary};
      }

      .input {
        padding: 10px;
        color: ${(props) => theme.colors.primary};
        border: 1px solid ${(props) => props.color};
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;

        &::placeholder {
          color: ${(props) => theme.colors.primary};
        }

        outline: none;
      }

      button {
        display: inline-block;
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  .button_image {
    .close {
      margin-bottom: auto;
      border: none;
      background-color: transparent;
      font-size: 12px;
      text-transform: uppercase;
      color: ${theme.colors.primary};
      font-family: ${theme.fonts.primary};
      cursor: pointer;

      display: flex;
      align-items: center;
      line-height: 2;
      gap: 10px;

      svg {
        transform: translateY(-1px);
      }
    }

    > span {
      margin-top: auto;
      transform: translateY(30px);
    }
  }

  @media screen and (max-width: 1020px) {
    right: 20px;
    left: 20px;
    bottom: 20px;

    padding-top: 60px;
    padding-bottom: 20px;
    padding-left: 20px;

    fle-direction: column;

    .hide {
      position: absolute;
      top: 15px;
      left: 10px;
    }

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .title-form {
      width: calc(100% - 20px);
      margin-right: 0;
      padding-right: 0;
      .form {
        > div {
          display: flex;
          flex-direction: column;
        }

        .input {
          text-align: center;
          padding: 15px;
        }
      }
    }

    .button_image {
      > span {
        display: none !important;
      }
    }
  }
`;
