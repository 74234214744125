import styled from "styled-components";
import { theme } from "../../themeConfig";

interface ButtonProps {
  color: string;
  backgroundColor: string;
}

export const SButton = styled.button<ButtonProps>`
  text-transform: uppercase;
  padding: 15px 25px;
  background-color: #000;
  color: ${(props) => (props.color ? props.color : "#fff")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "black"};
  border: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;

  .button__title {
    font-size: 24px;
    font-family: ${theme.fonts.secondary};
    font-weight: 700;
    margin: 0;
    line-height: 1;
    transform: translateY(3px);
  }

  svg {
    width: 30px;
    height: 30px;
    transform: rotate(0deg);
    transition: transform 0.3s;
  }

  &:hover svg {
    transform: rotate(360deg);
  }
`;
