import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom";
import { StyledModalBody, StyledModalOverlay } from "./Modal.styles";
import { StyledAgeCheckMenuContainer } from "./Modal.styles";
import { WPImage } from "../WPImage/WPImage";

const Modal = ({ show, children, options }: any) => {
  const [isBrowser, setIsBrowser] = useState(false);

  const { logo, image, background_image, message_1, message_2, title } =
    options;

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const Logo = () => {
    return (
      <>
        <StyledAgeCheckMenuContainer>
          <div className="modal-menu__wrapper">
            <div className="Modal-Menu__logo-container">
              <div className="Modal-Menu__logo-img">
                <WPImage image={logo} />
              </div>
            </div>
          </div>
        </StyledAgeCheckMenuContainer>
      </>
    );
  };

  const modalContent = show ? (
    <StyledModalOverlay>
      <Logo />
      <div className="modal-background-image">
        <WPImage image={background_image} />
      </div>
      <div className="modal__image">
        <WPImage image={image} />
      </div>
      <div className="main-inner">
        <div className="modal-wrapper">
          <div className="modal-container">
            <StyledModalBody>
              <h1 className="h2-caps">{title}</h1>
              <p className="bread-big">{message_1}</p>
              {children}
              <div className="modal__footer">
                <p className="bread-small">{message_2}</p>
              </div>
            </StyledModalBody>
          </div>
        </div>
      </div>
    </StyledModalOverlay>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("modal-root") as HTMLElement
    ) as JSX.Element;
  } else {
    return null;
  }
};

export default Modal;
