import styled from "styled-components";
import { theme } from "themeConfig";

export const StyledModalBody = styled.div`
  padding-top: 10px;
  color: ${theme.text.white};
  width: 100%;
  height: 100%;

  @media screen and (max-width: 582px) {
    padding: 0;
    background-color: ${theme.colors.black};
  }
`;

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
`;

export const StyledModalOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${theme.canvas.primary};

  .modal-background-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(12);
    z-index: -12;
  }

  .Modal-Menu__logo-img {
    width: 150px;
  }

  .modal-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .modal-container {
    display: flex;
    word-wrap: break-word;
    flex-direction: column;
    padding: 50px;
    background: ${theme.colors.black};
    z-index: 11;
    max-width: 900px;
  }

  h1 {
    color: ${theme.text.white};
    margin: 0;
    margin-bottom: 11px;
  }

  .bread-big {
    color: ${theme.text.white};
    margin: 0 0 20px;
  }

  button {
    text-align: right;
    height: 71px;
    margin-right: 17px;
    margin-bottom: 10px;
    background: ${theme.colors.darkGrey};
    border: none;
    white-space: nowrap;
    color: ${theme.text.white};

    padding: 0 30px 0 16px;

    &:nth-of-type(1):hover .checkmark {
      background: ${theme.brand.elderflower};
    }
    &:nth-of-type(2):hover .cross {
      background: #952641;
    }
    &:hover {
      background: ${theme.colors.darkGrey};
    }
  }

  .btn-content {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-left: 3px;
  }

  .checkmark {
    margin-right: 17px;
    border-radius: 50%;
    background: ${theme.colors.greyTertiary};
    display: inline-block;
    width: 44px;
    height: 44px;
    transform: rotate(45deg);
    transition: all 0.2s ease;
    flex-shrink: 0;
  }

  .checkmark_stem {
    position: absolute;
    width: 1.33px;
    height: 18.22px;
    background-color: ${theme.canvas.primary};
    left: 24px;
    top: 10px;
  }

  .checkmark_kick {
    position: absolute;
    width: 9.36px;
    height: 1.33px;
    background-color: ${theme.canvas.primary};
    left: 15px;
    top: 27px;
  }

  .cross {
    margin-right: 17px;
    border-radius: 50%;
    background: ${theme.colors.greyTertiary};
    display: inline-block;
    width: 44px;
    height: 44px;
    transform: rotate(0deg);
    transition: all 0.2s ease;
    flex-shrink: 0;
  }

  .cross_stem_1 {
    position: absolute;
    width: 1.33px;
    height: 22.63px;
    background-color: ${theme.canvas.primary};
    left: 22px;
    top: 11px;
    transform: rotate(135deg);
  }

  .cross_stem_2 {
    position: absolute;
    width: 1.33px;
    height: 22.63px;
    background-color: ${theme.canvas.primary};
    left: 22px;
    top: 11px;
    transform: rotate(45deg);
  }
  .modal__footer {
    display: flex;
    height: 100%;
    width: 100%;
    margin: 80px 0 0;

    p {
      color: ${theme.text.white};
      margin: 0;
    }
  }

  .modal__image {
    z-index: -11;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 55%;
    margin-top: -60px;
    justify-content: center;
    align-items: center;
    width: 598px;
    height: 750px;
    span {
      height: 100% !important;
    }
    img {
      object-fit: cover;
    }

    @media screen and (max-width: ${theme.breakpoints.medium}) {
      left: unset;
      right: 0;
      height: 600px;
      width: 450px;
      margin-top: -220px;
    }
  }

  // Mobile
  @media screen and (max-width: 670px) {
    background-size: 500%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-container {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-height: 500px;
      max-height: 80%;
      padding: 33px 17px;
      width: calc(100% - 40px);
    }

    h1 {
      font-size: 30px;
    }

    button {
      width: 100%;
      max-width: 500px;
    }

    .btn-content {
      margin: 0 auto;
    }

    .modal__footer {
    }

    .modal__image {
      display: none;
    }
  }
`;

export const StyledAgeCheckMenuContainer = styled.nav`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  z-index: 7;

  .styled-link {
    color: ${theme.text.primary};
    font-family: "Gotham Book";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.16em;
    text-transform: uppercase;

    text-decoration: none;
  }

  .modal-menu__wrapper {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Modal-Menu__logo-container {
    position: relative;
    width: 100vw;
    height: 50px;
    margin: ${theme.margin.desktop};
    cursor: pointer;
  }

  .Modal-Menu__logo-img {
    position: absolute;
    top: -3px;
    left: 20px;
  }

  .modal-menu__product-text {
    font-family: "Gotham Book";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    text-align: right;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: ${theme.text.darkBlue};
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    .Modal-Menu__logo-container {
      margin: ${theme.margin.mobile};
    }

    .Modal-Menu__logo-img {
      width: 113px;
      top: -10px;
    }
  }
`;
