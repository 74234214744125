import styled from "styled-components";
import { theme } from "themeConfig";

export const StyledMenuContainer = styled.nav<{ background: any }>`
  position: fixed;
  top: 62px;
  @media (max-width: 768px) {
    top: 95px;
  }
  left: 0;
  height: 80px;
  z-index: 2000;
  width: 100%;
  background: ${(props: any) =>
    props.background ? theme.canvas.primary : "transparent"};
  box-shadow: ${(props: any) =>
    props.background ? theme.shadows.primary : "none"};
  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 8;
  transition: all 0.3s ease-in-out;

  .styled-link {
    text-decoration: none;
  }

  .menu__wrapper {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .menu_wrapper_links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      height: 25px;
    }
  }

  .Menu__logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    cursor: pointer;
  }

  /* REMOVE WITH CACHE BUTTON!!! */
  .menu_temp-container-remove-with-cache-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Menu__logo-img {
    margin-right: 14px;
    width: 150px;
  }

  .menu__product-text {
    font-family: ${theme.fonts.secondary};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    text-align: right;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: ${theme.text.darkBlue};

    display: flex;
    flex-direction: row;
    gap: 2em;
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    display: none;
  }
`;
