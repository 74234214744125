import React from "react";
import { StyledMobileFooter } from "./MobileFooter.styled";
import Link from "next/link";
import { WPImage } from "../WPImage/WPImage";
import NewsLetter from "../NewsLetter/NewsLetter";

type Props = {};

const MobileFooter = ({ pageProps }: any) => {
  const {
    title,
    links,
    logo,
    bottom_row,
    link_group_1,
    link_group_3,
    news_letter,
    background_image_mobile,
  } = pageProps;

  return (
    <StyledMobileFooter backgroundImage={background_image_mobile}>
      <div className="main-inner">
        <Link href={"/"}>
          <div className="mobile-footer__container__brand-wrapper">
            <div className="mobile-footer__icon">
              <WPImage image={logo} />
            </div>
            <h4 className="mobile-footer__slogan h4-caps">{title}</h4>
          </div>
        </Link>

        <div className="mobile-footer-groups">
          <div className="mobile-footer-group mobile-footer-group-3">
            {link_group_3.title && (
              <h4 className="mobile-footer-group-title h6-caps">
                {link_group_3.title}
              </h4>
            )}
            <div className="mobile-footer-group-links">
              {link_group_3.links.map((link: any, index: number) => {
                return (
                  <Link href={link.link} key={index}>
                    <a className="mobile-footer__link bread-small">
                      {" "}
                      {link.name}
                    </a>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        <div className="mobile-footer-group mobile-footer-group-4">
          {news_letter.title && (
            <>
              <h4 className="mobile-footer-group-title h6-caps">
                {news_letter.title}
              </h4>
              <NewsLetter news_letter={news_letter} />
            </>
          )}
        </div>
        <div className="mobile-footer-socials">
          {bottom_row.social_media && (
            <p className="mobile-footer-group-title h6-caps">
              {bottom_row.social_media.text}
            </p>
          )}

          {bottom_row.social_media &&
            bottom_row.social_media.accounts.map(
              (account: any, index: number) => {
                return (
                  <div
                    className="mobile-footer-bottom__social-media-icon-wrapper"
                    key={index}
                  >
                    <Link href={account.link} passHref>
                      <a className="mobile-footer-bottom__social-media-link">
                        <div className="mobile-footer-bottom__social-media-icon">
                          <WPImage image={account.icon_black} />
                        </div>
                      </a>
                    </Link>
                  </div>
                );
              }
            )}
        </div>
      </div>

      {bottom_row && (
        <div className="mobile-footer-bottom">
          {bottom_row.emblem && (
            <div className="mobile-footer-bottom__emblem">
              <WPImage image={bottom_row.emblem.icon} />
            </div>
          )}
        </div>
      )}
    </StyledMobileFooter>
  );
};

export default MobileFooter;
