import styled from "styled-components";
import { theme } from "themeConfig";

export const StyledCartItem = styled.div`
  position: relative;
  box-sizing: border-box;
  /* border: 1px ${theme.colors.lightGrey}; */
  /* border-style: solid none; */
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 0;
  overflow-x: hidden;
  height: 100%;
  border-top: 1px solid ${theme.colors.lightGrey};
  .cart__line-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0px;
    color: ${theme.colors.lightGrey};
    background-color: ${theme.colors.lightGrey};
    height: 1px;
    border-width: 0;
    width: calc(100% - 37px);
  }

  .cart__img {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.lightGrey};
    height: 136px;
    width: 127px;
    margin-right: 10px;
    cursor: pointer;
  }

  .cart__info-wrapper {
    position: relative;
    display: flex;
    width: 78%;
    flex-direction: column;
    align-items: left;
    overflow: hidden;
  }

  .cart__remove-btn {
    position: absolute;
    top: -12px;
    right: -30px;
    display: inline-block;
    width: 44px;
    height: 44px;

    cursor: pointer;
    margin-right: 15px;
  }

  .cart__remove-btn__stem1 {
    position: absolute;
    width: 1.33px;
    height: 14px;
    background-color: ${theme.colors.grey};
    left: 22px;
    top: 11px;
    transform: rotate(-45deg);
  }
  .cart__remove-btn__stem2 {
    position: absolute;
    width: 1.33px;
    height: 14px;
    background-color: ${theme.colors.grey};
    left: 22px;
    top: 11px;
    transform: rotate(45deg);
  }

  .cart__top-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .cart__top-info__title {
    cursor: pointer;
  }

  .cart__product-description {
    color: ${theme.text.grey};
    margin-top: 8px;
  }

  .cart__product-price-wrapper {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
  }

  .cart__price {
    text-align: right;
  }

  .cart__quantity {
    border-radius: 0;
    border: 1px solid;
    margin: 2px;
    padding: 2px;
  }

  .cart__quantity[type="number"]::-webkit-inner-spin-button {
    opacity: 1;
  }

  .select {
    position: relative;
    display: inline-block;
    margin-right: 16px;
  }

  .select select {
    box-sizing: border-box;
    font-family: "Gotham Book";
    font-style: normal;
    font-weight: 700;
    font-size: 11px;

    line-height: 100%;

    width: 83px;

    cursor: pointer;
    padding: 10px 11px;
    outline: 0;
    border: 1px solid ${theme.colors.blue};
    border-radius: 3px;
    background: transparent;
    color: ${theme.colors.blue};
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .select select::-ms-expand {
    display: none;
  }
  .select select:hover,
  .select select:focus {
    color: ${theme.colors.blue};
    background: transparent;
  }
  .select select:disabled {
    opacity: 1;
    pointer-events: none;
  }

  .select_text {
    position: absolute;
    top: 51%;
    transform: translateY(-50%);
    left: 36px;

    font-family: "Gotham Book";
    font-size: 11px;
    color: ${theme.colors.blue};

    pointer-events: none;
  }

  .select_arrow {
    position: absolute;
    top: 12px;
    right: 15px;
    width: 2px;
    height: 2px;
    border: solid ${theme.colors.blue};
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    pointer-events: none;
  }

  .select select:hover ~ .select_arrow,
  .select select:focus ~ .select_arrow {
    border-color: ${theme.colors.blue};
  }
  .select select:disabled ~ .select_arrow {
    border-top-color: #ccc;
  }
`;
