import CartItem from "../CartItem";
import { StyledCartList } from "./CartList.styled";

function CartList({ products }: any) {
  const renderList = products?.map((product: any) => {
    const { quantity } = product;
    const { id, attributes }: any = product.product;
    const variationName = attributes[0].option;

    return (
      <StyledCartList key={id}>
        <CartItem product={{ quantity, variationName, ...product.product }} />
      </StyledCartList>
    );
  });
  return <>{renderList}</>;
}

export default CartList;
