import styled from "styled-components";
import { theme } from "themeConfig";

interface StyledFooterProps {
  backgroundImage?: string;
}

export const StyledFooter = styled.div<StyledFooterProps>`
  padding: ${theme.padding.mobile};
  padding-bottom: 0;
  width: 100%;
  background-color: ${theme.canvas.darkBlue};
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : "none"};
  background-size: cover;

  .main-inner {
    padding-left: 100px;
    padding-right: 100px;
    @media screen and (max-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  position: relative;

  .footer__container__brand-wrapper {
    display: flex;
    align-items: center;
    .footer__slogan {
      color: ${theme.text.lightGrey};
      margin: 0;
      margin-left: 20px;
    }
  }

  .footer-groups {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    flex-wrap: wrap;
  }

  .footer-group {
    padding-right: 50px;
    margin-bottom: 40px;
    @media screen and (max-width: ${theme.breakpoints.medium}) {
      flex-grow: 1;
    }
  }

  .footer-group-4 {
    padding: 0;
    @media screen and (max-width: ${theme.breakpoints.medium}) {
      width: 100%;
    }
  }

  .footer-group-title {
    margin: 0;
    color: ${theme.text.white};
    margin-bottom: 12px;
  }

  .footer-group-links {
    display: flex;
    flex-direction: column;
    .footer__link {
      color: ${theme.text.altGrey};
      text-decoration: none;
      margin-bottom: 12px;
      &:hover {
        color: ${theme.text.white};
      }
    }
  }

  // white part of the footer

  .footer__bottom__social-media-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 50px;
  }

  .footer-bottom__emblem {
  }

  .footer-socials {
    display: flex;
    align-items: center;
  }

  .footer-bottom__social-media-text {
    margin: 0;
    color: ${theme.text.white};
    font-family: ${theme.fonts.secondary};
    font-size: 18px;
    transform: translateY(2px);
  }

  .footer-bottom__social-media-icon-wrapper {
  }

  .footer-bottom__social-media-link {
    background: ${theme.canvas.inverted};
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 6px;
    &:hover {
      background: ${theme.brand.watermelon};
    }
  }

  .footer-bottom__social-media-icon {
    width: 100%;
    height: 100%;
    span,
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.small}) {
    display: none;
  }
`;
