import styled from "styled-components";
import { theme } from "themeConfig";

export const StyledCartList = styled.div`
  overflow-x: hidden;
  .wrapper {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 15px;
    margin: 5px;
    border-radius: 5px;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .cart-image {
    padding: 5px !important;
  }

  .title {
    font-family: Arial;
  }

  .product-price {
    font-family: Arial;
  }

  .input-quantity {
    width: 40px;
  }

  input[type="number"]::-webkit-inner-spin-button {
    opacity: 1;
  }

  .remove-btn {
    box-shadow: inset 20px 33px 10px 24px #ffffff;
    background: linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #dcdcdc;
    display: inline-block;
    cursor: pointer;
    color: #666666;
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    padding: 7px 24px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #ffffff;
    margin-top: 5px;
  }
  .remove-btn:hover {
    background: linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
    background-color: #f6f6f6;
  }
  .remove-btn:active {
    position: relative;
    top: 1px;
  }
`;
