import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

//remove last /
const url = process.env.BASE_URL?.replace(/\/$/, "") || "";

const api = new WooCommerceRestApi({
  url: url,
  consumerKey: process.env.CONSUMER_KEY || '',
  consumerSecret: process.env.CONSUMER_SECRET || '',
  version: "wc/v3",
});

export default api;
