import { SButton } from "./Button.styled";

const Button = ({ ...props }) => {
  return (
    <SButton
      onClick={props.onClick}
      style={{ ...props.styles }}
      color={props.color}
      backgroundColor={props.background}
    >
      {props.icon_left && (
        <div dangerouslySetInnerHTML={{ __html: props.icon_left }} />
      )}
      <div className="button__title">{props.title}</div>
      {props.icon_right && (
        <div dangerouslySetInnerHTML={{ __html: props.icon_right }} />
      )}
    </SButton>
  );
};

export default Button;
