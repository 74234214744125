import React from "react";
import { StyledCartTotalPriceWrapper } from "./CartTotalPrice.styled";

function CartTotalPrice({ products }: any) {
  let total = 0;
  for (let i = 0; i < products.length; i++) {
    let productPrice =
      parseInt(products[i].product.price) * products[i].quantity;
    total += productPrice;
  }
  return (
    <StyledCartTotalPriceWrapper>
      <div className="total-price__total-price">
        <p className="total-price__p-order details-big">ORDERBELOPP</p>
        <p className="total-price__p-order details-big">{total} SEK</p>
      </div>
      <div className="total-price__total-price">
        <p className="total-price__p-shipping bread-small">Frakt</p>
        <p className="total-price__p-shipping details-small">0 SEK</p>
      </div>
    </StyledCartTotalPriceWrapper>
  );
}

export default CartTotalPrice;
