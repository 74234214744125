import styled from "styled-components";
import { theme } from "themeConfig";

export const StyledCart = styled.div`
  font-family: "Gotham Book";
  display: flex;
  flex-direction: column;
  background: ${theme.canvas.primary};
  height: 100%;
  width: 525px;
  text-align: left;
  padding: 32px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  overflow-y: auto;

  -webkit-box-shadow: ${theme.shadows.primary};
  box-shadow: ${theme.shadows.primary};

  transition: transform 0.3s ease-in-out;
  transform: ${({ open }: { open: boolean }) =>
    open ? "translateX(0)" : "translateX(100%)"};

  .cart__title {
    margin: 0;
    margin-bottom: 10px;
  }

  .cart__slogan-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .cart__slogan {
    margin: 0 45px 0 0;
    line-height: 150%;
    color: ${theme.text.grey};
  }

  .this-is-the-amazing-div {
    width: 100%;
  }

  .cart__total-price-wrapper {
    background: ${theme.canvas.primary};
    display: flex;
    margin-top: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    width: 100%;
    border-top: 1px solid #ebebeb;
    padding-top: 20px;
  }

  .cart__total-price-div {
    display: flex;
    width: 100%;
    p {
      margin: 0;
    }
  }

  .cart__btn {
    box-sizing: border-box;
    margin-top: 20px;
    width: 100%;
  }

  @media screen and (max-width: 620px) {
    width: 100%;
    padding: 20px;
  }
`;
