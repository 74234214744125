import styled from "styled-components";

export const StyledAgeBanner = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1000;
  background: white;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;

  span,
  div {
    font-family: "Gotham Medium", "Arial";
    line-height: 1.5;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;

    &:last-child {
      border: 2px solid #252525;
      padding: 0.8rem 1.6rem;
      border-radius: 0.8rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 5px;
  }
`;
