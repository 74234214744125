import { StyledAgeBanner } from "./AgeBanner.styled";


export default function AgeBanner()  {

    return (
      <StyledAgeBanner>
        <div>
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.3033 16.375V8.25L7.99782 8.9875H5.5V7.625H8.88729V16.375H7.3033Z"
              fill="#252525"
            ></path>
            <path
              d="M14.0031 16.5C13.2964 16.5 12.6831 16.3917 12.1632 16.175C11.6433 15.9583 11.2412 15.6542 10.9569 15.2625C10.6726 14.8625 10.5305 14.3958 10.5305 13.8625C10.5305 13.3292 10.6686 12.8792 10.9447 12.5125C11.2209 12.1458 11.619 11.8667 12.1388 11.675C12.6587 11.4833 13.2801 11.3875 14.0031 11.3875C14.726 11.3875 15.3474 11.4833 15.8673 11.675C16.3953 11.8667 16.7974 12.15 17.0735 12.525C17.3578 12.8917 17.5 13.3375 17.5 13.8625C17.5 14.3958 17.3538 14.8625 17.0614 15.2625C16.7771 15.6542 16.3709 15.9583 15.8429 16.175C15.323 16.3917 14.7098 16.5 14.0031 16.5ZM14.0031 15.225C14.5879 15.225 15.0509 15.1 15.3921 14.85C15.7332 14.6 15.9038 14.2542 15.9038 13.8125C15.9038 13.3792 15.7332 13.0375 15.3921 12.7875C15.0509 12.5375 14.5879 12.4125 14.0031 12.4125C13.4182 12.4125 12.9592 12.5375 12.6262 12.7875C12.2932 13.0375 12.1266 13.3792 12.1266 13.8125C12.1266 14.2542 12.2932 14.6 12.6262 14.85C12.9592 15.1 13.4182 15.225 14.0031 15.225ZM14.0031 12.2375C13.3451 12.2375 12.7805 12.15 12.3094 11.975C11.8383 11.7917 11.4727 11.5292 11.2128 11.1875C10.961 10.8375 10.8351 10.4208 10.8351 9.9375C10.8351 9.4375 10.9651 9.00833 11.225 8.65C11.4931 8.28333 11.8667 8 12.346 7.8C12.8252 7.6 13.3776 7.5 14.0031 7.5C14.6366 7.5 15.1931 7.6 15.6723 7.8C16.1516 8 16.5252 8.28333 16.7933 8.65C17.0614 9.00833 17.1954 9.4375 17.1954 9.9375C17.1954 10.4208 17.0654 10.8375 16.8055 11.1875C16.5537 11.5292 16.1881 11.7917 15.7089 11.975C15.2296 12.15 14.661 12.2375 14.0031 12.2375ZM14.0031 11.2375C14.5067 11.2375 14.9047 11.1292 15.1971 10.9125C15.4896 10.6875 15.6358 10.3875 15.6358 10.0125C15.6358 9.62083 15.4855 9.31667 15.1849 9.1C14.8844 8.88333 14.4904 8.775 14.0031 8.775C13.5157 8.775 13.1258 8.88333 12.8333 9.1C12.5409 9.31667 12.3947 9.62083 12.3947 10.0125C12.3947 10.3875 12.5369 10.6875 12.8212 10.9125C13.1136 11.1292 13.5076 11.2375 14.0031 11.2375Z"
              fill="#252525"
            ></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.573 11.9994C21.573 17.6546 16.9886 22.2391 11.3334 22.2391C5.6782 22.2391 1.09375 17.6546 1.09375 11.9994C1.09375 6.34421 5.6782 1.75977 11.3334 1.75977C16.9886 1.75977 21.573 6.34421 21.573 11.9994ZM19.9302 11.9994C19.9302 16.7473 16.0813 20.5962 11.3334 20.5962C9.18788 20.5962 7.22594 19.8102 5.71978 18.5105L17.8445 6.3858C19.1442 7.89195 19.9302 9.8539 19.9302 11.9994ZM16.7985 5.36305L4.69704 17.4645C3.47225 15.979 2.73661 14.0751 2.73661 11.9994C2.73661 7.25154 6.58552 3.40262 11.3334 3.40262C13.4091 3.40262 15.313 4.13826 16.7985 5.36305Z"
              fill="#D9082E"
            ></path>
          </svg>
          <span>Ej under 18 år</span>
        </div>
        <div>
          Denna produkt innehåller nikotin som är ett mycket
          beroendeframkallande ämne.
        </div>
      </StyledAgeBanner>
    );

}